import { Component, OnInit } from '@angular/core';

import {ModalController, NavParams} from '@ionic/angular';

import { ApiService } from '../../service/api.service';

import { HttpClient } from '@angular/common/http'; 

import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";



@Component({
  selector: 'app-searchtruckfilter',
  templateUrl: './searchtruckfilter.page.html',
  styleUrls: ['./searchtruckfilter.page.scss'],
})
export class SearchtruckfilterPage implements OnInit {
    
  selectopts:any = [];
  citylist:any = [];
  stateName:any = [];
  truck: FormGroup; 
  
  type:any = [20, 40, 45];
  
  filterarr:any = [];
  
  constructor(private modalContr: ModalController, private api: ApiService, public httpClient: HttpClient, public formBuilder: FormBuilder) {
      this.countrydata();
      
   }
  
  searchaom(e){
      console.log(e.text);
      let country = "India";
      let query = 'city='+ e.text +'&country='+ country +'&action=searchcity';
      this.api.fetchData(query).subscribe((res : any)=>{
          this.citylist = res;
      });
  }
  
  optionchange(value, type){
      this.selectopts[type] = value; 
  }
  
  
  closeModal(){
    this.modalContr.dismiss();
  }

  ngOnInit() {
      this.truck = this.formBuilder.group({
            areaofmov: new FormControl(""),
            weight: new FormControl("")
     });
  }
  
  countrydata(){
        var countryName1:any = [];
        
        let postData = "action=Contries";
        this.api.fetchData(postData).subscribe((data : any) => {
            if(data){
                countryName1 = data['Countries'];
                var stateName1 = countryName1[100].States;
                var stateName = [];
                stateName1.forEach(function (value, key)
                {
                    stateName.push({'id':key, 'state':value['StateName']});
                });
                this.stateName = stateName;
                console.log(this.stateName);
            }
        }, error => { 
               // console.log(error);
        }); 
    }
    
    
    async filtertruck(){
        this.filterarr['type'] = (this.selectopts['type'])? this.selectopts['type'] : ''; 
        this.filterarr['weight'] = (this.truck.controls["weight"].value)? this.truck.controls["weight"].value: 900000;
        this.filterarr['areaofmov'] = (this.truck.controls["areaofmov"].value.state)? this.truck.controls["areaofmov"].value.state : '';
        await this.modalContr.dismiss(this.filterarr);
    }

}
