import { Component, OnInit } from '@angular/core';

import {ModalController, NavParams} from '@ionic/angular';

import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";

import { ApiService } from '../../service/api.service';

import { HttpClient } from '@angular/common/http'; 


@Component({
  selector: 'app-searchloadfilter',
  templateUrl: './searchloadfilter.page.html',
  styleUrls: ['./searchloadfilter.page.scss'],
})
export class SearchloadfilterPage implements OnInit {
    
  selectopts:any = [];
  citylist:any = [];
  typingTimer;
  pickpoint:any = [];
  droppoint:any = [];
  filterarr:any = [];
  rate;

  constructor(private modalContr: ModalController, private api: ApiService, public httpClient: HttpClient) { }
  
  closeModal(){
    this.modalContr.dismiss();
  }
  
  optionchange(value, type){
      this.selectopts[type] = value; 
  }

  ngOnInit() {
  }
  
  searchaom(e){
      let country = "India";
      let query = 'city='+ e.text +'&country='+ country +'&action=searchcity';
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(()=>{
          this.api.fetchData(query).subscribe((res : any)=>{
          this.citylist = res;
        });
      }, 600);
  }
  
  citychange(event){
     
  }

    async filtertruck(){
        this.filterarr['type'] = (this.selectopts['type'])? this.selectopts['type'] : ''; 
        this.filterarr['weight'] = (this.selectopts["weight"])? this.selectopts["weight"]: 900000;
        
        this.filterarr['pickpoint'] = (this.pickpoint[0])? this.pickpoint[0] : '';
        this.filterarr['droppoint'] = (this.droppoint[0])? this.droppoint[0] : '';
        
        this.filterarr['status'] = (this.selectopts["status"])? this.selectopts["status"] : '';
        this.filterarr['payment'] = (this.selectopts["payment"])? this.selectopts["payment"] : '';
        this.filterarr['rate'] = (this.rate)? this.rate : '';
        await this.modalContr.dismiss(this.filterarr);
    }
}
